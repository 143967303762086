import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SignUpForm from '../../components/SignupForm';
import { Button } from 'antd';
import './style.css';

const HomePage = () => {
  const [isSignup, setIsSignup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Sync isSignup with the URL path
  useEffect(() => {
    if (location.pathname === '/signup') {
      setIsSignup(true);
    } else {
      setIsSignup(false);
    }
  }, [location.pathname]);

  const handleClick = () => {
    // setIsSignup(true);
    navigate('/signup');
  };

  return (
    <main>
      <h2 className="page-title">Planea para una jubilación tranquila</h2>
      {isSignup ? (
        <SignUpForm />
      ) : (
        <div className="content-container">
          <div className="text-content">
            <p>
              No puedes trabajar para siempre. Te mereces la oportunidad de
              relajarte y disfrutar la vida. Nosotros podemos ayudarte a planear
              para una jubilación tranquila. Deja que tu dinero crezca mientras
              estas trabajando.
            </p>
            <p>
              <span className="highlighted-text">Como funciona crecer?</span>{' '}
              Nosotros creamos tu cuenta de retiro y manejamos las inversiones.
              Tú puedes elegir cuánto quieres poner en tu cuenta cada mes.
            </p>
            <p>
              <span className="highlighted-text">Por qué crecer?</span> Hemos
              creado un sistema que invierte automáticamente tu dinero con
              estrategias basadas en tu edad y tolerancia al riesgo.
            </p>
            <p>Ya es hora de empezar!</p>
            <Button
              type="primary"
              className="create-account-button"
              onClick={handleClick}
            >
              Regístrate para mas información
            </Button>
          </div>
          {/* <div className="image-container">
            <img
              src="/images/Crecer_homepage_pic.png"
              className="homepage-image"
            />
          </div> */}
        </div>
      )}
    </main>
  );
};

export default HomePage;
