import React from 'react';

import './style.css';

const Footer = () => {
  return (
    <footer className="app-footer">
      <div className="container">
        <span>2024 Crecer. Reservados todos los derechos.</span>
      </div>
    </footer>
  );
};

export default Footer;
