import React, { useState, useEffect } from 'react';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, ConfigProvider } from 'antd';
import { signUp } from '../../utils/api';
import './style.css';

const SignUpForm = () => {
  const [formState, setFormState] = useState({
    username: '',
    email: '',
  });
  const [error, setError] = useState(null);
  const [signupSuccess, setSignupSuccess] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleFormSubmit = async (formValues) => {
    try {
      console.log(formValues);
      const response = await signUp(formValues);
      if (!response.ok) {
        setError(
          'No se pudo crear una cuenta. Por favor verifique tu información y vuelve a intentarlo.'
        );
      }
      const data = await response.json();
      console.log('account created', data);
      setSignupSuccess(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#3BC14A',
          },
        }}
      >
        {!signupSuccess ? (
          <Form
            className="login-form"
            layout="vertical"
            onFinish={handleFormSubmit}
            initialValues={formState}
          >
            <Form.Item
              label="Nombre"
              name="username"
              rules={[
                {
                  required: true,
                  message: '¡Por favor ingresa tu nombre de usuario!',
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Nombre"
              />
            </Form.Item>
            <Form.Item
              label="Correo"
              name="email"
              rules={[
                {
                  required: true,
                  message: '¡Por favor ingresa tu correo!',
                  type: 'email',
                },
              ]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                type="email"
                placeholder="correo@correo.com"
              />
            </Form.Item>
            <Form.Item className="login-form-item">
              <Button
                type="primary"
                className="login-form-button"
                htmlType="submit"
              >
                Registrarte
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <div>
            <p className="p-tag">Exito! Estaremos en contacto!</p>
          </div>
        )}
      </ConfigProvider>
      {error && <div>{error}</div>}
    </div>
  );
};

export default SignUpForm;
