import React, { useState, useEffect } from 'react';
import { Menu, Row, Col, ConfigProvider } from 'antd';
import { Link } from 'react-router-dom';

import 'antd/dist/antd';
import './style.css';

const Navbar = () => {
  return (
    <nav>
      <Row align="middle" justify="space-between">
        <Col xs={8} sm={8} md={8} lg={8}>
          <h1 className="title">
            <Link to="/" className="home-link">
              Crecer
            </Link>
          </h1>
        </Col>
      </Row>
    </nav>
  );
};

export default Navbar;
